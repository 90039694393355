<template>
  <div v-show="chats">
    <div class="chats-list__period-name-container">
      <input v-if="selectModeOn" class="form-check-input me-2 mb-1"
             type="checkbox"
             @click.stop="selectGroup"
             v-model="groupIsDeleted" >
      <span class="chats-list__period-name">{{ chatPeriods[period].name }}</span>
    </div>

    <div class="chats-list__period-group">
      <router-link v-for="chat in chats"
                   :id="chat.id"
                   :key="chat.id"
                   :to=" Number(chat.id) === Number($store.state.currentChat) ? {name: 'Home'} : {name: 'Chats', params: {chat_id: chat.id}}"
                   class="list-group-item"
                   @mouseenter="onMouseEnter(chat.id)"
                   @mouseleave="onMouseLeave(chat.id)"
                   :class="{'bg-primary-01 list-group-item-current': Number(chat.id) === Number($route.params.chat_id)}" ref="descriptions">
        <ChatActions :chat="chat" :ref="`chatEditor-${chat.id}`"/>
      </router-link>
    </div>
  </div>
</template>

<script>
  import ChatActions from "@/components/chats/ChatActions.vue";
  import {chatPeriods} from "@/data/chats_periods";

  export default {
    components: {ChatActions},

    data() {
      return {
        chatPeriods: chatPeriods,
        groupIsDeleted: false,
      };
    },

    props: {
      period: String,
    },

    computed: {
      chats() {
        if (this.$store.state.chats.length === 0) return null;

        const periodMethod = chatPeriods[this.period].method;

        const chatsOnPeriod = this.$store.state.chats.data.filter(chat => {
          const updatedAt = new Date(chat.updated_at);
          return periodMethod(updatedAt);
        });

        return chatsOnPeriod.length ? chatsOnPeriod : null;
      },

      selectModeOn() {
        return this.$store.state.selectModeOn;
      },
    },

    methods: {
      onMouseEnter(chatId) {
        const chatEditorRef = this.$refs[`chatEditor-${chatId}`][0];
        if (chatEditorRef) {
          chatEditorRef.onMouseEnter();
        }
      },

      onMouseLeave(chatId) {
        const chatEditorRef = this.$refs[`chatEditor-${chatId}`][0];
        if (chatEditorRef) {
          chatEditorRef.onMouseLeave();
        }
      },

      selectGroup() {
        this.$store.commit('setChatsDeleteStatus', { chats: this.chats, value: !this.groupIsDeleted });
      }
    }
  }
</script>

<style scoped>
.list-group-item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  padding: 8px 4px 8px 8px;
  margin: 0 6px 0 14px;
  border-radius: 6px;
  color: var(--main-text-color) !important;
  background-color: var(--block-bg-color);
}

.list-group-item:last-child {
  border-radius: 6px;
}

.list-group-item:first-child {
  border-radius: 6px;
}

.chats-list__period-group {
  padding: 8px 0;
  gap: 4px;
}


.list-group-item + .list-group-item {
  margin-top: 4px;
}

.list-group-item-current {
  color: var(--primary-color) !important;
}

.chats-list__period-name-container {
  padding: 20px 30px 6px 22px;
}

.chats-list__period-name {
  opacity: 50%;
  font-size: 11px;
}
</style>