<template>
  <div class="chat-creator__container">
    <div class="chat-creator__panel">
      <AiTitle :ai_name="ai.name" :ai_logo="ai.logo" size_class="ai-icon"/>
      <div class="ai-description w-100 small fw-light text-wrap my-3" v-html="ai.description"></div>

      <form class="w-100" @submit="createChat">
        <label class="form-label w-100" for="models">Выберите версию</label>
        <SelectOptions class="w-75" :items="versions"
                       name="Операция"
                       :value="selectedVersion"
                       @update:value="value => selectedVersion = value" />

        <div class="ai-version-description">
          <transition-group  name="description" tag="div">
            <span class="small fw-light text-wrap my-2" :key="selectedVersion" v-html="getVersionDescription(selectedVersion)"></span>
          </transition-group>
        </div>

        <button class="btn btn-primary" type="submit">Создать чат</button>
      </form>
    </div>
  </div>
</template>

<script>
  import AiTitle from "@/components/icons/AiTitle.vue";
  import SelectOptions from "@/components/reusable/SelectOptions.vue";

  export default {
    components: {SelectOptions, AiTitle},
    props: {
      ai: Object,
    },

    data() {
      return {
        selectedVersion: null,
      };
    },

    computed: {
      versions() {
        return this.$store.getters.aisVersionsBySlug(this.ai.slug);
      },
    },

    created() {
      this.selectedVersion = this.ai.versions.find(v =>v.default).slug;
    },

    methods: {
      async createChat(e) {
        e.preventDefault()

        const chat = await this.$store.dispatch('storeChat', {
          name: `Чат от ${new Date().toLocaleString()}`,
          model: this.ai.slug,
          version: this.selectedVersion,
          is_default_name: this.name ? 0 : 1,
        });
        await this.$router.push({name: 'Chats', params: {chat_id: chat.id}})
      },

      getVersionDescription(selectedVersion) {
        const version = this.ai.versions.find(({slug}) => slug === selectedVersion)
        if (version) {
          return version.description;
        }
        return '';
      },

    }
  }
</script>
<style scoped>
  .chat-creator__container {
    flex: 0 0 auto;
    width: 33.33333333%;
    padding: 10px;
  }

  .chat-creator__panel {
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
    height: 100%;
    padding: 24px;
    align-content: start;
    box-shadow: var(--card-shadow-color);
  }

  .ai-description {
    height: 60px;
    overflow: hidden;
  }

  .ai-version-description {
    height: 40px;
    margin-bottom: 24px;
    margin-top: 10px;
    overflow: hidden;
  }
</style>