<template>
  <div class="w-100">
    <SectionTitle title="Личная информация" />

    <form class="account-info__container">
      <div class="d-flex gap-3">
        <div class="w-50">
          <label class="form-label mb-0" for="name">Имя</label>
          <input class="form-control"  type="text" id="name"
                 name="name" v-model="name" required>
        </div>
        <div class="w-50">
          <label class="form-label mb-0" for="email">Email</label>
          <input class="form-control" :class="{'is-invalid': errors.message}" type="email" id="email" name="email" v-model="email"
                 autocomplete="email" required>
          <div class="invalid-feedback">{{ errors.message }}</div>
        </div>
      </div>

        <button class="btn btn-primary mt-3" data-bs-target="#saveAccountChanges" data-bs-toggle="modal" @click.prevent>Сохранить</button>
    </form>

    <Modal id="saveAccountChanges" modal-name="Подтвердите действие" :is-footer-shown="true">
      <template #body>
        Сохранить изменения?
      </template>
      <template #footer>
        <button class="btn btn-primary" @click="saveData" data-bs-dismiss="modal">Сохранить</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import {Api} from "@/helpers/Api";
  import Modal from "@/components/reusable/Modal.vue";
  import SectionTitle from "@/components/reusable/SectionTitle.vue";

  export  default {
    components: {SectionTitle, Modal},
    data() {
      return {
        errors: [],
        name: '',
        email: '',
        modalMessage: '',
      }
    },

    props: {
      api: Api,
    },

    async beforeMount() {
      if (this.$store.state.name && this.$store.state.username) {
        this.name = this.$store.state.name;
        this.email = this.$store.state.username;
      } else {
        const response = await this.api.getUserData();
        if (await response) {
          this.name = response.data.name;
          this.email = response.data.email;
        }
      }
    },

    methods: {
      async saveData(e) {
        e.preventDefault();
        this.modalMessage = '';

        const response = await this.api.saveUserData({
          name: this.name,
          email: this.email,
        });
        if (await response.hasOwnProperty('error')) {
          this.modalMessage = await response.error === '' ? 'Ошибка при сохранении данных' : await response.error;
        } else {
          this.modalMessage = await 'Изменения сохранены.';
          await this.$store.commit('setName', this.name);
          await this.$store.commit('setUsername', this.email);
        }
      }
    }
  }
</script>

<style scoped>
  .account-info__container {
    background-color: var(--block-bg-color);
    padding: 1.65rem;
    border-radius: 6px;
  }
</style>