<template>
  <transition-group name="list" tag="div"
                    class="chats-list list-group list-group-flush"
                    @scrollend="handleChatsListScrolling" >

    <ChatsPeriodList v-for="period in Object.keys(chatPeriods)" :key="period" :period="period" />

    <div class="loading-block d-flex w-100 justify-content-center">
      <LoadingProcess v-if="loading" />
    </div>

    <transition name="slide-up" mode="out-in">
      <div v-show="selectModeOn" class="chats-list__actions-block">
        <button class="btn btn-outline-primary" @click="switchSelectMode">Отменить</button>
        <button class="btn btn-danger" data-bs-target="#chatsMassDelete" data-bs-toggle="modal">Удалить</button>
      </div>
    </transition>

    <transition name="slide-up" mode="out-in">
      <div v-show="!selectModeOn" class="chats-list__documents-block">
        <span class="chats-list__documents-link" @click="navigateToRoute('/policy')">Политика конфиденциальности</span>
        <span class="chats-list__documents-link" @click="navigateToRoute('/oferta')">Оферта</span>
      </div>
    </transition>

    <Modal id="chatsMassDelete" modal-name="Удалить чаты?" :is-footer-shown="true">
      <template #body>
        <div>
          При удалении чата стирается вся информация, введеная и полученная вами ранее.
          <br>
          Вы уверены, что хотите удалить чаты?
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отменить</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteSelectedChats">Удалить</button>
      </template>
    </Modal>

    <Modal v-for="chat in chats" :key="chat.id" :id="'chatLink' + chat.id" modal-name="Поделиться">
      <template #body>
        <div>
          <label class="form-label small" for="public_link">Публичная ссылка для просмотра чата</label>
          <div class="d-flex justify-content-between">
            <input type="text" class="form-control me-2"
                   id="public_link" disabled
                   :placeholder="formatLink(chat.id, chat.public_link_token)"
                   aria-label="Публичная ссылка на чат">
            <button
              class="btn btn-primary"
              type="button"
              data-bs-dismiss="modal"
              @click="copyPublicLink(formatLink(chat.id, chat.public_link_token))">
              Скопировать
            </button>
          </div>
        </div>
      </template>
    </Modal>

  </transition-group>
</template>

<script>
import Modal from "@/components/reusable/Modal.vue";
import {chatPeriods} from "@/data/chats_periods";
import ChatsPeriodList from "@/components/chats/ChatsPeriodList.vue";
import LoadingProcess from "@/components/reusable/LoadingProcess.vue";

export default {
  components: {LoadingProcess, ChatsPeriodList, Modal},
    data() {
        return {
          chatPeriods: chatPeriods,
          loading: false,
        };
    },

    async beforeMount() {
      this.$store.dispatch('getChats');
    },

    computed: {
      chats() {
        return this.$store.state.chats ? this.$store.state.chats.data : [];
      },

      selectModeOn() {
        return this.$store.state.selectModeOn;
      },
    },

    methods: {
      formatLink(chatId, token) {
        return window.location.origin + `/chats/${chatId}/public?chat_token=` + token;
      },

      copyPublicLink(link) {
        navigator.clipboard.writeText(link);
      },

      switchSelectMode() {
        this.$store.commit('setSelectMode', false);
      },

      async deleteSelectedChats() {
        const deletingChatIds = this.$store.state.chats.data
          .filter((chat) => chat.isDelete)
          .map((chat) => chat.id);

        await this.$store.dispatch('deleteChats', deletingChatIds);
        this.$store.commit('setSelectMode', false);

        if(deletingChatIds.includes(Number(this.$store.state.currentChat))) {
          this.$router.push('/chats/create');
        }
      },

      async handleChatsListScrolling() {
        const element =  document.querySelector('.chats-list');
        const scrolledToBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 10;

        if (scrolledToBottom && this.$store.state.chats.meta.next_cursor) {
          this.loading = true;
          await this.$store.dispatch('getChats');
          this.loading = await false;
        }
      },

      navigateToRoute(path) {
        this.$router.push({ path });
      },
    }
}
</script>

<style scoped>
.chats-list__actions-block {
  position: absolute;
  width: 260px;
  background-color: var(--block-bg-color);
  display: flex;
  justify-content: space-between;
  padding: 20px 14px;
  bottom: 0;
  border-top: 1px solid var(--border-color);
}

.chats-list__documents-block {
  position: absolute;
  width: 260px;
  background-color: var(--block-bg-color);
  padding: 18px 14px;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chats-list__documents-link {
  color: var(--main-text-color);
  font-size: 13px;
  line-height: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.chats-list__documents-link:hover {
  color: var(--primary-color);
}

.chats-list {
  display: flex;
  overflow-y: auto;
  padding-bottom: 120px;
}

.chats-list::-webkit-scrollbar-thumb {
  background: var(--block-bg-color);
}

.chats-list::-webkit-scrollbar-track {
  background: var(--block-bg-color);
}

.chats-list:hover::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.chats-list:hover::-webkit-scrollbar-track {
  background: var(--primary-color-01);
}

.loading-block {
  height: 50px;
  padding-bottom: 10px;
}

.slide-up-enter-active {
  animation: slide-up .3s ease-out both;
}

.slide-up-leave-active {
  animation: slide-up .3s ease-in both reverse;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>