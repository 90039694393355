<template>
  <Modal id="infoChat" modal-name="Информация">
    <template #body>
      <span>Стоимость чата: {{ chatCost }} руб.</span>
      <br>
      <span>Всего сообщений: {{ messages ? messages.length : 0 }}</span>
    </template>
  </Modal>
  <InfoIcon role="button"
            size="14"
            data-bs-target="#infoChat"
            data-bs-toggle="modal"
            @click="getChatData" />
</template>

<script>
import Modal from "@/components/reusable/Modal.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";

export default {
  components: {
    InfoIcon,
    Modal,
  },

  data() {
    return {
      chatCost: '',
    }
  },

  props: {
    messages: Array,
    chatId: Number,
  },

  methods: {
    async getChatData() {
      const chat = await this.$store.dispatch('getChat', this.chatId);
      this.chatCost = await chat.data.cost;
    },
  }
}
</script>

<style scoped>

</style>