<template>
  <div>
    <div class="mt-4 mb-3">
      <span class="fs-4">Вход в приложение</span>
    </div>

    <button class="btn btn-primary" data-bs-target="#changePassword" data-bs-toggle="modal">Изменить пароль</button>

    <Modal id="changePassword" modal-name="Подтвердите действие" :is-footer-shown="true">
      <template #body>
        Отправить письмо на Ваш E-mail для смены пароля?
      </template>
      <template #footer>
        <button class="btn btn-primary" data-bs-target="#emailSent" data-bs-toggle="modal" @click="sendEmail">Отправить</button>
      </template>
    </Modal>

    <Modal id="emailSent" :is-footer-shown="true">
      <template #body>
        <span v-html="modalMessage"></span>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ок</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import {Api} from "@/helpers/Api";
  import Modal from "@/components/reusable/Modal.vue";

  export default {
    components: {Modal},
    data() {
      return {
        modalMessage: '',
      }
    },

    props: {
      api: Api,
    },

    methods: {
      async sendEmail() {
        this.modalMessage = '';
        const response = await this.api.getResetPasswordUrl(this.$store.state.username);
        if (await response.hasOwnProperty('error')) {
          this.modalMessage = await response.error === '' ? 'Ошибка при отправке' : await response.error;
        } else {
          this.modalMessage = await 'Письмо отправлено.';
        }
      }
    }
  }
</script>


<style scoped>

</style>