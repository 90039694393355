<template>
  <div class="d-flex flex-nowrap w-100 justify-content-between" :id="'edit' + chat.id">
    <div class="d-flex text-nowrap align-items-center overflow-hidden">
      <input v-if="selectModeOn" class="form-check-input me-2 mb-1" type="checkbox"
             @click.stop
             v-model="chat.isDelete">
      <AiDynamicIcon :ai_logo="chat.version.logo" size_class="ai-icon-sm"/>
      <input class="form-control chat-name-input fs-6 py-0" ref="chatNameInput"
             v-if="isEditing"
             @click.stop.prevent
             @input.stop.prevent
             @blur="saveChatName"
             @keydown.enter="saveChatName"
             v-model="chat.name">
      <div v-if="!isEditing" class="text-nowrap overflow-hidden scroll-hidden" ref="chatName">
        <div class="sidebar-item__text" :class="{ 'text-ellipsis': !isMouseOn }">{{ chat.name }}</div>
      </div>
    </div>

    <div class="d-flex flex-nowrap">
      <div class="dropdown ms-3 chat-name__dropdown"
           :class="{ 'chat-name__dropdown-visible': isMouseOn && !selectModeOn && !isEditing }">
        <MenuIcon
          v-if="!isEditing"
          icon="fas fa-ellipsis-h"
          @click="adjustDropdownPosition"
          class="dropdown-toggle"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false" />
        <DropdownList class="position-absolute">
          <template #menu>
            <li>
              <button @click.prevent="showEditForm" class="btn btn-dropdown">
                <EditIcon />
                <span class="mx-2">Редактировать</span>
              </button>
            </li>
            <li>
              <button @click.prevent="switchSelectMode" class="btn btn-dropdown">
                <ChooseIcon />
                <span class="mx-2">Выбрать</span>
              </button>
            </li>
            <li>
              <button @click.prevent="generateLink" class="btn btn-dropdown"
                      :data-bs-target="'#chatLink' + chat.id"
                      data-bs-toggle="modal">
                <ShareIcon />
                <span class="mx-2">Поделиться</span>
              </button>
            </li>
            <li><hr class="dropdown-divider"></li>
            <li>
              <button class="btn btn-dropdown-danger text-danger"
                      @click.prevent
                      :data-bs-target="'#deleteChatFromList' + chat.id"
                      data-bs-toggle="modal">
                <DeleteIcon size="22" color="danger"/>
                <span class="mx-2">Удалить</span>
              </button>
            </li>
          </template>
        </DropdownList>
      </div>

      <div v-if="chat.has_not_seen_messages">
        <ChatNotificationIcon />
      </div>
    </div>

    <Modal class="main-text-color" :id="'deleteChatFromList' + chat.id" modal-name="Удалить чат" :is-footer-shown="true">
      <template #body>
        <div>
          При удалении чата стирается вся информация, введеная и полученная вами ранее.
          <br>
          Вы уверены, что хотите удалить чат?
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click.prevent>Отменить</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click.prevent="deleteChat">Удалить</button>
      </template>
    </Modal>
  </div>
 </template>

<script>
  import DropdownList from "@/components/reusable/DropdownList.vue";
  import MenuIcon from "@/components/icons/MenuIcon.vue";
  import AiDynamicIcon from "@/components/icons/AiDynamicIcon.vue";
  import EditIcon from "@/components/icons/EditIcon.vue";
  import ChooseIcon from "@/components/icons/ChooseIcon.vue";
  import ShareIcon from "@/components/icons/ShareIcon.vue";
  import DeleteIcon from "@/components/icons/DeleteIcon.vue";
  import ChatNotificationIcon from "@/components/icons/ChatNotificationIcon.vue";
  import Modal from "@/components/reusable/Modal.vue";

  export default {
    components: {
      Modal,
      ChatNotificationIcon,
      DeleteIcon,
      ShareIcon,
      ChooseIcon,
      EditIcon,
      AiDynamicIcon,
      MenuIcon,
      DropdownList,
    },

    data() {
      return {
        isEditing: false,
        isMouseOn: false,
        previousName: '',
        animationFrameId: null,
        isDelete: false,
      }
    },

    props: {
      chat: Object,
    },

    computed: {
      selectModeOn() {
        return this.$store.state.selectModeOn;
      },
    },

    mounted() {
      this.previousName = this.chat.name;
    },

    methods: {
      async switchSelectMode() {
        await this.$store.dispatch('getAllChats');
        await this.$store.commit('setSelectMode', true);
      },

      async saveChatName() {
        if (await this.$store.dispatch('updateChatName', this.chat)) {
          this.isEditing = false;
        } else {
          this.isEditing = false;
          this.chat.name = this.previousName;
        }
      },

      deleteChat() {
        if (this.$store.dispatch('deleteChat', {id: this.chat.id})) {
          this.$router.push({ path: '/chats/create' });
        }
      },

      showEditForm() {
        this.isEditing = true;
        this.$nextTick(() => {
          this.$refs.chatNameInput.focus();
        });
      },

      generateLink() {
        console.log(123)
        if (!this.chat.public_link_token) {
          this.$store.dispatch('generateToken', {id: this.chat.id});
        }
      },

      onMouseEnter() {
        this.isMouseOn = true;
        const element = this.$refs.chatName;

        if (element) {
          let scrollAmount = 0;
          const scrollSpeed = 0.5;

          const step = () => {
            scrollAmount += scrollSpeed;
            if (scrollAmount <= element.scrollWidth - element.clientWidth) {
              element.scrollLeft = scrollAmount;
              this.animationFrameId = window.requestAnimationFrame(step);
            }
          };
          this.animationFrameId = window.requestAnimationFrame(step);
        }
      },

      onMouseLeave() {

        setTimeout(() => {
          this.isMouseOn = false;

          document
            .querySelector(`#edit${this.chat.id}`)
            .querySelector('.dropdown-menu')
            .classList.remove('show');
        });


        if (this.animationFrameId !== null) {
          window.cancelAnimationFrame(this.animationFrameId);
          this.animationFrameId = null;
        }

        const element = this.$refs.chatName;
        if (element) {
          element.scrollLeft = 0;
        }
      },

      adjustDropdownPosition() {
        const dropdownButton = this.$el.querySelector('.dropdown-toggle');
        const dropdownMenu = this.$el.querySelector('.dropdown-menu');

        if (!dropdownMenu || !dropdownButton) return;

        requestAnimationFrame(() => {
          const buttonRect = dropdownButton.getBoundingClientRect();

          let topPos = buttonRect.bottom;
          let leftPos = buttonRect.left;

          dropdownMenu.style.position = 'fixed';
          dropdownMenu.style.top = `${topPos - 15}px`;
          dropdownMenu.style.left = `${leftPos}px`;
        });
      }
    },

  }
</script>

<style scoped>
  .scroll-hidden::-webkit-scrollbar {
    display: none;
  }
  .scroll-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .chat-name__dropdown {
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .chat-name__dropdown-visible {
    opacity: 1;
  }
</style>