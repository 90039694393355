<template>
  <div class="w-100 d-flex flex-column mb-4">
    <SectionTitle title="Нейросети" />

    <div class="ai-select__container row-container flex-grow-1 rounded-2">
      <div class="row">
        <ChatCreator v-for="ai in ais" :ai="ai" :key="ai.id"/>
      </div>
    </div>
  </div>
</template>

<script>
 import ChatCreator from "@/components/chats/ChatCreator.vue";
 import SectionTitle from "@/components/reusable/SectionTitle.vue";

 export default {
   components: {SectionTitle, ChatCreator},

   computed: {
     ais() {
       return this.$store.state.ais;
     },
   },

 }
</script>
<style scoped>
  .ai-select__container {
    overflow-y: auto;
    padding: 14px 16px 14px 24px;
    background-color: var(--block-bg-color);
  }

  .ai-select__container::-webkit-scrollbar-thumb {
    background: var(--block-bg-color);
  }

  .ai-select__container::-webkit-scrollbar-track {
    background: var(--block-bg-color);
  }

  .ai-select__container:hover::-webkit-scrollbar-thumb {
    background: var(--primary-color);
  }

  .ai-select__container:hover::-webkit-scrollbar-track {
    background: var(--primary-color-01);
  }
</style>